// libs
import { useState, useEffect } from 'react'

// check if string is non-whitespace
export const isNonWhitespaceString = (str) => {
    return str && !/^\s*$/.test(str)
}

// check if dom is loaded
export const useDomLoaded = () => {
    const [domLoaded, setDomLoaded] = useState(false)

    useEffect(() => {
        setDomLoaded(true)
    }, [])

    return domLoaded
}

// remove all special characters from phone (to use in href='tel:')
export function phone(str) {
	return (
		'tel:' + str.replace(/[^0-9]/g, '')
	)
}

// simple function to use along <a href='mailto:'>
export function email(str) {
	return (
		'mailto:' + str
	)
}

// slugify
export function slugify(str) {
    return String(str)
        .normalize('NFKD')
        .replace(/[\u0300-\u036f]/g, '')
        .trim()
        .toLowerCase()
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');
}

// calc vw / vh
export const vw = (coef) => window.innerWidth * (coef/100)
export const vh = (coef) => window.innerHeight * (coef/100)

// limit characters
export function limitCharacters(text, limit) {
    if (text) {
        if (text.length <= limit) {
            return text
        } else {
            return text.slice(0, limit) + '...'
        }
    }
    return ''
}

// get all focusable elements inside the container
export const getFocusableElements = (container) => {
    return container.querySelectorAll(
        'a, button, input, textarea, select, [tabindex]:not([tabindex="-1"])'
    )
}

// get all focusable elements outside the container
export const getFocusableElementsOutside = (container) => {
    const allFocusableElements = document.querySelectorAll(
        'a, button, input, textarea, select, [tabindex]:not([tabindex="-1"])'
    )

    // filter out elements that are inside the container
    return Array.from(allFocusableElements).filter(
        (element) => !container.contains(element)
    )
}
