import React from 'react'

import HeroBanner from '../../../react4xp/components/HeroBanner'

function HeroPart({ title, text, links, image, video }) {
    return (
        <HeroBanner
            type={video ? 'video' : 'image'}
            title={title}
            image={image}
            video={video}
            content={text}
            listLinks={links}
        />
    )
}

export default (props) => <HeroPart {...props} />
